import React from "react";

import classNames from "classnames";

const ColourGroup = ({ text, groupId, colour, selected = false, callback }) => {
  return (
    <div className="colourGroupTextContainer" onClick={() => callback(groupId)}>
      <div
        style={{ backgroundColor: colour }}
        className={classNames({
          colourGroup: true,
          colourSelected: selected,
        })}
      ></div>
      <div className="text">{text}</div>
    </div>
  );
};

export default ColourGroup;
