import React, { useEffect, useContext, useState } from "react";
import FlashNoteCollection from "../components/FlashNoteCollection/FlashNoteCollection";
import AppContainer from "../components/AppContainer/AppContainer";
import CollectionOptions from "../components/CollectionOptions/CollectionOptions";

import { RealmContext } from "../contexts/RealmContext";
import PageTitle from "../components/PageTitle/PageTitle";
import { HelpTextData } from "../data/HelpTextData";
import PageMessage from "../components/PageMessage/PageMessage";

const Creator = () => {
  const RealmApp = useContext(RealmContext);
  const app = RealmApp.app;
  const [userLoggedIn, setUserLoggedIn] = useState(app.currentUser !== null);

  useEffect(() => {
    setUserLoggedIn(app.currentUser !== null);
  }, [app]);
  return (
    <>
      <PageMessage text="Thank you for signing up to the FlashNotez beta!" type="success" />
      <PageTitle pageTitle="Create" />
      {userLoggedIn ? (
        <>
          <FlashNoteCollection>
            <CollectionOptions />
            <AppContainer />
          </FlashNoteCollection>
        </>
      ) : (
        <PageMessage text={HelpTextData.notLoggedIn} type="info" />
      )}
    </>
  );
};

export default Creator;
