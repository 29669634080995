import React from "react";
import { useSpring, animated, config } from "react-spring";

const AnimateContent = ({ children, delay = 0, animateStyle = "scale" }) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: 1,
    config: { duration: 1000 },
    delay: delay,
  });

  const defaultAnimation =
    animateStyle == "scale"
      ? {
          opacity: x.to({ range: [0, 0.5, 1], output: [0, 1, 1] }),
          scale: x.to({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [1, 0.8, 0.9, 1.4, 1.3, 1.1, 1.03, 1],
          }),
        }
      : {
          opacity: x.to({ range: [0, 0.5, 1], output: [0, 1, 1] }),
          scaleY: x.to({
            range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
            output: [0, 0, 0.1, 0.4, 0.8, 1.2, 0.9, 1],
          }),
        };
  return <animated.div style={defaultAnimation}>{children}</animated.div>;
};

export default AnimateContent;
