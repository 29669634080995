import React, { useEffect } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { handleAuthRedirect } from "realm-web";

const HandleOAuth = () => {
  useEffect(() => {
    handleAuthRedirect();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", width: "100vw", alignItems: "center", justifyContent: "center" }}>
      <BounceLoader color="#469cff" size="100" />
      <h3>Authenticating...</h3>
    </div>
  );
};

export default HandleOAuth;
