import React from "react";
import TextField from "@material-ui/core/TextField";

import "./MailingListSignup.scss";

const MailingListSignup = () => {
  return (
    <div id="mc_embed_signup">
      <form
        action="https://FlashNotez.us1.list-manage.com/subscribe/post?u=093c8c6f23f89636af3a9acb7&amp;id=c8bf85ad34"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <input
            type="email"
            defaultValue=""
            name="EMAIL"
            className="email"
            id="mce-EMAIL"
            placeholder="email address"
            required
          />
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_093c8c6f23f89636af3a9acb7_c8bf85ad34"
              tabIndex="-1"
              defaultValue=""
            />
          </div>
          <div className="optionalParent">
            <div className="clear foot">
              <input
                type="submit"
                defaultValue="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MailingListSignup;
