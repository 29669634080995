import React from "react";

import "./DefaultButton.scss";

const DefaultButton = ({ enabled = true, text, image, callback, className, visible = true, label = null }) => {
  let img;
  if (image) {
    img = (
      // <div className="imagesBtn">
      <img src={image} alt="FlashNote Card" />
      // </div>
    );
  }
  return (
    <>
      <button aria-label={label ? label : text} style={!visible ? { display: "none" } : {}} className={className} disabled={!enabled} onClick={(e) => callback(e)} tabIndex="-1">
        {img}
        {text}
      </button>
    </>
  );
};

export default DefaultButton;
