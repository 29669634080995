import React, { useContext } from "react";
import { Modal } from "react-responsive-modal";

import "./LoginForm.scss";

import GoogleLogin from "react-google-login";
import { Credentials } from "realm-web";

import { RealmContext } from "../../contexts/RealmContext";
import EmailPassword from "./EmailPassword";

const LoginForm = ({ open, onCloseModal = () => void 0, onSuccess = void 0, canCloseModal = true }) => {
  const RealmApp = useContext(RealmContext);
  const app = RealmApp.app;
  const [, setUser] = RealmApp.user;

  const handleEmailPasswordResult = (res, user) => {
    if (res) {
      setUser(user);
      onSuccess();
    }
  };

  const handleLoginWithGoogle = (response = "") => {
    const credentials = Credentials.google({ idToken: response.tokenId });
    app.logIn(credentials).then((user) => {
      setUser(user);
      onSuccess();
    });
  };

  const closeModalButton = <div className="modalCloseButton">X</div>;

  return (
    <>
      <Modal
        open={open}
        onClose={canCloseModal ? onCloseModal : () => void 0}
        classNames={{ modal: "customModal", overlay: "customOverlay" }}
        closeIcon={canCloseModal ? closeModalButton : ""}
        closeOnEsc={canCloseModal}
        showCloseIcon={canCloseModal}
        onOverlayClick={canCloseModal ? onCloseModal : () => void 0}
        center
      >
        <div className="modalBody">
          <EmailPassword handleEmailPasswordResult={handleEmailPasswordResult} />
          <hr className="orHr" />
          {/* <button onClick={() => handleLoginWithGoogle()}>Login With Google</button> */}
          <GoogleLogin
            clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
            buttonText="Login with Google"
            className="googleBtn"
            onSuccess={handleLoginWithGoogle}
            onFailure={handleLoginWithGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </Modal>
    </>
  );
};

export default LoginForm;
