import React, { useContext, useEffect, useState } from "react";
import DefaultButton from "../DefaultButton/DefaultButton";
import StatusMessage from "../StatusMessage/StatusMessage";
import { InputContext } from "../../contexts/InputTextContext";
import { withRouter } from "react-router-dom";
import { RealmContext } from "../../contexts/RealmContext";
import { flashNoteCollections } from "../../api/flashNoteCollections";

import "./CollectionOptions.scss";

const getTime = () => {
  return new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

const CollectionOptions = ({ history }) => {
  const RealmApp = useContext(RealmContext);
  const app = RealmApp.app;
  const [masterText, setMasterText] = useContext(InputContext);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [upToDate, setUpToDate] = useState(false);

  // Called to push updates to the DB
  const makeNewUpdateRequest = async (fnCollection) => {
    const flashNoteCollection = new flashNoteCollections(app, fnCollection);
    const res = await flashNoteCollection.updateFnCollection();
    if (res.success) {
      handleSuccessfulUpdate();
    } else {
      console.error(res);
    }
  };

  // Called when up to date with the DB
  const handleSuccessfulUpdate = () => {
    setUpToDate(true);
    setLastUpdate(getTime());
  };

  // Begin DB update when the masterText is updated
  useEffect(() => {
    let delayedRequest = null;
    if (!upToDate) {
      delayedRequest = setTimeout(() => {
        if (lastUpdate !== null) {
          makeNewUpdateRequest(masterText);
        } else {
          handleSuccessfulUpdate();
        }
      }, 1500);
    } else {
      setUpToDate(false);
    }

    return () => {
      clearTimeout(delayedRequest);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterText]);

  return (
    <div className="collectionOptionsContainer">
      <DefaultButton enabled={true} text="←" label="Back" callback={() => history.push("/home")} />
      <div className="collectionOptionsHeader">
        <input className="titleText collectionOptionsTitle" value={masterText.title} onChange={(e) => setMasterText({ ...masterText, title: e.target.value })} placeholder="New Collection" />
        <StatusMessage text={lastUpdate !== null && upToDate ? `Changes saved at ${lastUpdate}` : ``} status={upToDate} />
      </div>
    </div>
  );
};

export default withRouter(CollectionOptions);
