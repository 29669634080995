import React, { useState } from "react";
import GroupStyles from "../../styles/export.module.scss";
import "./ColourGroups.scss";

import ColourGroup from "../ColourGroup/ColourGroup";

const ColourGroups = ({ changeGroupSelection, initialNumGroups, editingText }) => {
  const [colourGroups, setColourGroups] = useState(() => {
    let arr = [];
    for (var i = 0; i <= initialNumGroups; i++) {
      arr.push({ colour: GroupStyles[`cg${i}`] });
    }
    return arr;
  });
  const [selectedColour, setSelectedColour] = useState(0);

  const createNewColourGroup = () => {
    if (colourGroups.length < 16) {
      setColourGroups((prevColourGroups) => [...prevColourGroups, { colour: GroupStyles[`cg${prevColourGroups.length}`] }]);
      changeCurrentColour(colourGroups.length);
    }
  };

  const changeCurrentColour = (groupId) => {
    setSelectedColour(groupId);
    changeGroupSelection(groupId);
  };

  return (
    <div className="colourGroupContainer">
      {colourGroups.map(({ colour }, index) => {
        let isSelected = false;
        if (index === selectedColour && !editingText) {
          isSelected = true;
        }
        return <ColourGroup key={index} groupId={index} colour={colour} selected={isSelected} callback={changeCurrentColour} />;
      })}
      {colourGroups.length < 16 ? <ColourGroup text="＋" callback={createNewColourGroup} /> : <></>}
    </div>
  );
};

export default ColourGroups;
