// Collection ID received from URL
export function checkCollectionId(collectionId, masterText) {
  if (collectionId === "new") {
    return false;
  }
  if (collectionId === undefined) {
    // No remote collection ID in URL (either new collection or local)
    if (masterText && masterText.id) {
      // Local collection exists
      return masterText.id;
    } else {
      // New collection
      return false;
    }
  } else {
    // Remote collection ID in URL
    return collectionId;
  }
}
