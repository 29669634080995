import React, { useState, createContext } from "react";
import { App as RealmApp } from "realm-web";

export const RealmContext = createContext();

export const RealmProvider = (props) => {
  const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
  const app = new RealmApp({ id: REALM_APP_ID });
  const [user, setUser] = useState(app.currentUser);

  const logoutUser = async () => {
    try {
      await user.logOut();
      setUser(null);
    } catch (e) {
      console.error(`Error logging out. ${e}`);
    }
  };

  return <RealmContext.Provider value={{ app: app, user: [user, setUser], logout: logoutUser }}>{app && props.children}</RealmContext.Provider>;
};
