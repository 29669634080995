import React, { useState, useEffect, useRef } from "react";

import { isMobile } from "../../data/HelpTextData";

import "./NoteFragment.scss";

const NoteFragment = ({ index, group, selectingMode, selected, hint, hintPosition, updateHint, selectedClass, text, innerRef }) => {
  const [tempSelected, setTempSelected] = useState(selected);
  const [showHintButton, setShowHintButton] = useState(false);
  const [showHintInput, setShowHintInput] = useState(false);
  const timer = useRef(null);
  const input = useRef(null);

  const mobileClient = isMobile();
  let noteFragClass = `noteFrag noteFragHighlightMode hcg${group}`;
  if (mobileClient) {
    noteFragClass = "noteFragM";
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    showHintInput ? input.current.focus() : void 0;
  }, [showHintInput]);

  useEffect(() => {
    setTempSelected(selected);
    setShowHintButton(false);
    setShowHintInput(false);
  }, [selected]);

  const handleMouseOver = () => {
    if ((selectedClass === 4 || selectedClass === 1) && selectingMode === 0) {
      setShowHintButton(true);
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setShowHintButton(false);
      }, 500);
    }
  };

  const handleMouseLeave = () => {
    if (selectedClass === 4 && selectingMode === 0) {
      setShowHintButton(false);
    }
  };

  const handlePlusHintButtonClicked = () => {
    setShowHintButton(false);
    setShowHintInput(true);
  };

  const handleInputKeyUp = (e) => {
    if (e.key === "Enter") {
      let inputValue = input.current.value.trim();
      if (inputValue !== "" && inputValue.length < 500) {
        updateHint(index, inputValue);
        setShowHintInput(false);
      }
    }
  };

  return hint ? (
    <>
      <span ref={innerRef} dangerouslySetInnerHTML={{ __html: text }} className={`hint ${hintPosition === 1 || hintPosition === 4 ? "hintLabel" : ""}`}></span>
    </>
  ) : (
    <>
      <span
        //onMouseOver={() => handleMouseOver()}
        onMouseMove={() => handleMouseOver()}
        ref={innerRef}
        dangerouslySetInnerHTML={{ __html: text }}
        className={tempSelected ? `wordSelected ws${selectedClass} cg${group} ${noteFragClass}` : noteFragClass}
        fragment={index}
      ></span>
      {showHintButton && !showHintInput && (
        <div className="addHintContainer">
          <button onMouseUp={() => handlePlusHintButtonClicked()} onMouseOver={() => clearTimeout(timer.current)} onMouseLeave={() => handleMouseLeave()} className={`addHintButton`}>
            +Hint
          </button>
        </div>
      )}
      {showHintInput && (
        <div className="addHintContainer">
          <div className="addHintInputContainer">
            <div className="hintClose" onClick={() => setShowHintInput(false)}>
              ✕
            </div>
            <input onBlur={() => setShowHintInput(false)} onKeyUp={(e) => handleInputKeyUp(e)} ref={input} className="addHintInput" type="text" placeholder="Type your hint..." />
            <span className="statusMsg">Press ⏎ to save</span>
          </div>
        </div>
      )}
    </>
  );
};

export default NoteFragment;
