import React from "react";
import { FaYoutube, FaInstagram } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import AnimateContent from "../AnimateContent/AnimateContent";
import MailingListSignup from "../MailingListSignup/MailingListSignup";
import SocialDetailsItem from "../SocialDetailsItem/SocialDetailsItem";
import "./SocialDetailsGrid.scss";

const SocialDetailsGrid = () => {
  return (
    <div className="social-details-container mt-3">
      <AnimateContent delay={50}>
        <SocialDetailsItem
          Icon={FaYoutube}
          link="https://www.youtube.com/channel/UCrRp75oHZx6HISQJvRKLDWA"
          titleClass="youtube"
          titleText="Atticus"
          contentText="Follow the journey of creating FlashNotez, covering concepts, design and
        code!"
        />
      </AnimateContent>
      <AnimateContent delay={200}>
        <SocialDetailsItem
          Icon={FaInstagram}
          link="https://www.instagram.com/flashnotez/"
          titleClass="instagram"
          titleText="FlashNotez"
          contentText="Get updates and preview snippets of features being developed!"
        />
      </AnimateContent>
      <AnimateContent delay={350}>
        <SocialDetailsItem
          Icon={MdEmail}
          link="https://eepurl.com/hts7M5"
          titleClass="mailing-list"
          titleText="Mailing List"
          content={<MailingListSignup />}
        />
      </AnimateContent>
    </div>
  );
};

export default SocialDetailsGrid;
