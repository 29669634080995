// import { Timestamp } from "mongodb";

export class flashNoteCollections {
  constructor(app, fnCollection = null) {
    if (app.currentUser !== null) {
      this.fnCollections = app.currentUser
        .mongoClient("mongodb-atlas")
        .db("flashnotez")
        .collection("fncollections");
      this.user_id = app.currentUser.id;
      this.fnCollection = fnCollection;
    } else {
      return false;
    }
  }

  async updateFnCollection() {
    if (this.fnCollection === null) return { success: false, res: null };
    let res;
    let success = false;
    let insertDoc = this.fnCollection;
    let localDocId = insertDoc.id;
    let lastRemoteUpdate = insertDoc.remoteDate;
    let isRemoteSaved = insertDoc.isRemoteSaved;
    insertDoc.owner = this.user_id;
    insertDoc.localDate = new Date(); // update with now

    const createNew = async () => {
      try {
        if (insertDoc.title !== "" || insertDoc.flashnotez.length !== 0) {
          insertDoc.remoteDate = new Date(); // update with now
          insertDoc.isRemoteSaved = true;
          res = await this.fnCollections.insertOne(insertDoc);
          success = res.hasOwnProperty("insertedId");
          if (!success) {
            insertDoc.remoteDate = lastRemoteUpdate; // update with now
            insertDoc.isRemoteSaved = false;
            throw Error("Not created");
          }
        } else {
          success = true;
          res = "No content";
        }
      } catch (error) {
        res = error;
      }
    };

    if (isRemoteSaved) {
      // if has been saved remotely in past
      // Update
      try {
        res = await this.fnCollections.updateOne({ id: localDocId }, insertDoc);
        success = res.hasOwnProperty("matchedCount") && res.matchedCount > 0;
        if (!success) throw Error("Not found");
      } catch (error) {
        res = error;
      }
    } else {
      // Create New
      await createNew();
    }

    return { updatedDoc: insertDoc, result: res, success: success };
  }

  async getAllFnCollections() {
    //await this.fnCollections.deleteMany({ flashnotez: { $size: 6 } }); // Delete all flashnotez
    const options = { limit: 5, sort: { remoteDate: -1 } };
    return await this.fnCollections.find({}, options);
  }

  async getFnCollectionById(collectionId) {
    let success = true;
    const options = { limit: 1, sort: { remoteDate: -1 } };
    const res = await this.fnCollections.find({ id: collectionId }, options);
    return { success: success, result: res };
  }

  // async deleteAllCollections() {
  //   const res = await this.fnCollections.deleteMany({});
  //   return true;
  // }
}
