import React from "react";

import "./HelpText.scss";

const HelpText = ({ text }) => {
  return (
    <div>
      <div id="helpText" className="instructions">
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
    </div>
  );
};

export default HelpText;
