import "./App.scss";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Components
import { DefaultContainer } from "./misc/DefaultContainer";
import { RealmProvider } from "./contexts/RealmContext";
import { InputProvider } from "./contexts/InputTextContext";
import HandleOAuth from "./pages/HandleOAuth";
import FeaturePreview from "./pages/FeaturePreview";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <RealmProvider>
          <InputProvider>
            <Switch>
              <Route exact path="/handleOAuth" component={HandleOAuth} />
              {process.env.REACT_APP_FEATURE_PREVIEW_BUILD ? (
                <Route component={FeaturePreview} />
              ) : (
                <Route component={DefaultContainer} />
              )}
            </Switch>
          </InputProvider>
        </RealmProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
