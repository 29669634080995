import React from "react";

import "./SocialDetailsItem.scss";

const SocialDetailsItem = ({
  Icon,
  link = "#",
  titleClass = "",
  titleText = "",
  contentText = "",
  content = "",
}) => {
  return (
    <div className="social-details-item">
      <a className="social-details-link" target="_blank" href={link}>
        <div className={`social-details-item-title ${titleClass}`}>
          <Icon size="30" fill="#fff" />
          <span>
            <h2>{titleText}</h2>
          </span>
        </div>
      </a>
      <div className="social-details-content">
        <p>{contentText}</p>
        {content}
      </div>
    </div>
  );
};

export default SocialDetailsItem;
