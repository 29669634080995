import React, { useContext, useState } from "react";
import { Link, withRouter, useLocation } from "react-router-dom";

import { RealmContext } from "../../contexts/RealmContext";
import { InputContext } from "../../contexts/InputTextContext";

import "react-responsive-modal/styles.css";
import { FaSignOutAlt } from "react-icons/fa";

import logo from "../../assets/Logo.svg";
import "./TopBar.scss";
import DefaultButton from "../DefaultButton/DefaultButton";

const TopBar = ({ history }) => {
  const location = useLocation().pathname;
  const RealmApp = useContext(RealmContext);
  const [, setMasterText] = useContext(InputContext);
  const [user] = RealmApp.user;
  const logoutUser = RealmApp.logout;

  const handleLogoutUser = () => {
    setMasterText(null);
    logoutUser();
    window.location.reload();
  };

  return (
    <>
      <div className="topBar">
        <div className="topBarLeft">
          <Link to="/home">
            <img src={logo} alt="FlashNotez logo" />
          </Link>
        </div>
        <div className="topBarRight">
          {user && (
            <>
              <DefaultButton
                className={`navBarBtn ${
                  location === "/home" ? "navBarBtnSelected" : ""
                }`}
                text="Home"
                callback={() => history.push("/home")}
              />
              <DefaultButton
                className={`navBarBtn ${
                  location === "/create" || location === "/create/new"
                    ? "navBarBtnSelected"
                    : ""
                }`}
                text="Create"
                callback={() => history.push("/create/new")}
              />
              <DefaultButton
                className={`navBarBtn ${
                  location === "/learn" ? "navBarBtnSelected" : ""
                }`}
                text="Learn"
                callback={() => history.push("/learn")}
              />
            </>
          )}
          <DefaultButton
            className={`navBarBtn ${
              user ? "navBarBtnLogout" : "navBarBtnLogin"
            } ${location === "/creator" ? "navBarBtnSelected" : ""}`}
            text={user ? <FaSignOutAlt /> : "Login"}
            label={user ? "Logout" : "Login"}
            callback={() => (user ? handleLogoutUser() : history.push("/"))}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(TopBar);
