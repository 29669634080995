import React from "react";

import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";

import "./StatusMessage.scss";

const StatusMessage = ({ status, text }) => {
  const override = css`
    display: block;
    margin: 2px 2px 2px 5px;
  `;

  return (
    <div className="msgContainer">
      <span className={`statusMsg ${status ? "msgSuccess" : ""}`}>{text}</span>
      <div className={status ? "loaderHide" : ""}>
        <BeatLoader color={"rgb(162, 209, 162)"} loading={true} css={override} size={"5px"} />
      </div>
    </div>
  );
};

export default StatusMessage;
