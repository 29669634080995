import { useState, useContext } from "react";
import { RealmContext } from "../../contexts/RealmContext";
import { Credentials } from "realm-web";

import "./LoginForm.scss";
import MoonLoader from "react-spinners/MoonLoader";
import TextField from "@material-ui/core/TextField";
import DefaultButton from "../DefaultButton/DefaultButton";
import ClientMessage from "../ClientMessage/ClientMessage";
const assert = require("assert");
const formTypes = { login: "Login", register: "Register" };

const EmailPassword = ({ handleEmailPasswordResult }) => {
  const RealmApp = useContext(RealmContext);
  const app = RealmApp.app;
  const [user, setUser] = RealmApp.user;

  const [currentForm, setCurrentForm] = useState(formTypes.login);
  const [submittingForm, setSubmittingForm] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [formMessage, setFormMessage] = useState({ type: "", message: "" });

  const handleEmailPasswordSubmit = async (e) => {
    e && e.preventDefault();
    setFormMessage({ type: "", message: "" });

    if (email !== "" && password !== "") {
      setSubmittingForm(true);
      switch (currentForm) {
        case "Login": {
          const res = await loginWithEmailPassword(email, password);
          if (res.success) {
            handleEmailPasswordResult(true, res.data);
          } else {
            setFormMessage({ type: "Error", message: "Email or password incorrect" });
          }
          break;
        }

        case "Register": {
          const res = await registerWithEmailPassword(email, password);
          if (res.success) {
            setUser(res.data);
            handleEmailPasswordResult(true);
          } else {
            setFormMessage({ type: "Error", message: "An error ocurred while registering" });
          }
          break;
        }

        default:
          console.error("Type of form unrecognised");
          break;
      }
    } else {
      setFormMessage({ type: "Error", message: "Fill in both email and password" });
    }
  };

  const registerWithEmailPassword = async (email, password) => {
    try {
      await app.emailPasswordAuth.registerUser(email, password);
      const res = await loginWithEmailPassword(email, password);
      if (res.success) {
        return { success: true, data: res.data };
      } else {
        throw res.message;
      }
    } catch (error) {
      console.error(error);
      return { success: false, message: error };
    }
  };

  const loginWithEmailPassword = async (email, password) => {
    const credentials = Credentials.emailPassword(email, password);
    try {
      const user = await app.logIn(credentials);
      assert(user.id === app.currentUser.id);
      return { success: true, data: user };
    } catch (error) {
      console.error(error);
      return { success: false, message: error };
    }
  };

  return (
    <>
      {submittingForm ? (
        <MoonLoader />
      ) : user ? (
        `Logged in!`
      ) : (
        <form noValidate autoComplete="off">
          <h1>{currentForm}</h1>
          <TextField onChange={(e) => setEmail(e.target.value)} value={email} required id="outlined-email-input" label="Email" type="email" autoComplete="current-email" variant="outlined" />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
            id="outlined-password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="outlined"
          />
          <DefaultButton text={currentForm} className="submitBtn" callback={handleEmailPasswordSubmit} />
          <div style={{ marginTop: "10px" }}>
            <ClientMessage type={formMessage.type} text={formMessage.message} />
          </div>
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentForm(currentForm === "Login" ? formTypes.register : formTypes.login);
            }}
          >
            {currentForm === "Login" ? formTypes.register : formTypes.login} instead?
          </a>
        </form>
      )}
    </>
  );
};

export default EmailPassword;
