import React, { useState, useEffect, useContext } from "react";
import "./FlashNoteCollection.scss";
import { InputContext } from "../../contexts/InputTextContext";
import { RealmContext } from "../../contexts/RealmContext";
import { useParams, withRouter } from "react-router-dom";
import { flashNoteCollections } from "../../api/flashNoteCollections";
import { checkCollectionId } from "./FlashNoteCollectionHelper.js";
import GridLoader from "react-spinners/GridLoader";

const FlashNoteCollection = ({ children, history }) => {
  const RealmApp = useContext(RealmContext);
  const app = RealmApp.app;
  const [masterText, setMasterText] = useContext(InputContext);
  const [showCollection, setShowCollection] = useState(false);
  const { collectionId } = useParams();

  useEffect(() => {
    let initialConfig = async () => {
      setShowCollection(false);

      let localCollectionId = checkCollectionId(collectionId, masterText);
      if (localCollectionId !== false) {
        // if there is a query ID
        let fetch;
        const flashNoteCollection = new flashNoteCollections(app);
        try {
          fetch = await flashNoteCollection.getFnCollectionById(localCollectionId);
        } catch (error) {
          history.push("/dashboard");
          return;
        }

        if (fetch.success && fetch.result[0]) {
          let remoteCollection = fetch.result[0];
          delete remoteCollection._id;
          if (masterText.id === remoteCollection.id) {
            // Check whether date is newer than current master
            // if they don't match, the newest is set as master triggering a remote update
            // if they do match, nothing happens
            let remoteDate = new Date(remoteCollection.remoteDate);
            let localDate = new Date(masterText.localDate);
            if (remoteDate > localDate) {
              setMasterText(remoteCollection);
            } else if (localDate > remoteDate) {
              setMasterText(masterText);
            }
          } else {
            // Conflict between local and remote collection ID's, override local as assume change
            setMasterText(remoteCollection);
          }
        } else {
          // a new collection
          setMasterText(null, true);
        }
      } else {
        // a new collection
        setMasterText(null, true);
      }
    };
    initialConfig().then(() => {
      setShowCollection(true);
    });
    return () => {
      initialConfig = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionId]);

  return (
    <>
      {showCollection ? (
        children
      ) : (
        <div className="loader-container">
          <GridLoader color="#469cff" />
        </div>
      )}
    </>
  );
};

export default withRouter(FlashNoteCollection);
