class Flashify {
  static startTagDelimiter = "{"; // default: ∆ anki: "{"
  static numStartDelimiters = 2; // default: 1 anki: 2
  static endTagDelimiter = "}"; // default: "∇" anki: "}"
  static numEndDelimiters = 2; // default: 1 anki: 2
  static charPreceedingGroupNum = "c"; // default: "" anki: "[c]"
  static numColonsAfterFirstDelimiter = 2; // default: 1 anki: 2

  // static parseText(text) {
  //   const regStartTag = new RegExp(`([${this.startTagDelimiter}]{${this.numStartDelimiters}})\\b([${this.charPreceedingGroupNum}][0-9]{1,2})\\b([:]{${this.numColonsAfterFirstDelimiter}})`, "gi"); // ∆1:
  //   const regEndTag = new RegExp(`([${this.endTagDelimiter}]{${this.numEndDelimiters}})`, "gi"); // ∇
  //   const regSplitBySpacesAndNewLines = /(\r\n|\n|\r|\s)/g;

  //   const regSpaceAfterEndTag = new RegExp(`([${this.endTagDelimiter}]{${this.numEndDelimiters}})`, "gi");
  //   const regSpaceAfterStartTag = new RegExp(`([${this.startTagDelimiter}]{${this.numStartDelimiters}}[0-9]{1,}[:]{${this.numColonsAfterFirstDelimiter}})`, "gi");

  //   var temp = text;
  //   //text.replace(regSpaceAfterEndTag, "$1 ").replace(regSpaceAfterStartTag, " $1");

  //   var split = temp.split(regSplitBySpacesAndNewLines).filter(function (el) {
  //     return el !== "" && el !== " ";
  //   });

  //   let prevWord = { group: 0, selected: false, containedEnd: false };

  //   return split.map((word, index) => {
  //     let text = "";
  //     let hint = "";
  //     let selected = false;
  //     let group = 0;

  //     let checkIfStart = word.match(regStartTag);
  //     let checkIfEnd = word.match(regEndTag);

  //     // Type 1 block:   ∆1:abc∇
  //     if (checkIfStart !== null && checkIfEnd !== null) {
  //       //text = word.match(regMidText)[0];
  //       text = word.replace(regStartTag, "").replace(regEndTag, "");
  //       group = getGroupFromStartTag(checkIfStart, this.startTagDelimiter, this.charPreceedingGroupNum);
  //       selected = true;
  //       prevWord.containedEnd = true;
  //     } else if (checkIfStart !== null && checkIfEnd === null) {
  //       // Type 2 block:   ∆1:abc
  //       // text = word.match(regRightText)[0];
  //       text = word.replace(regStartTag, "");
  //       group = getGroupFromStartTag(checkIfStart, this.startTagDelimiter, this.charPreceedingGroupNum);
  //       selected = true;
  //       prevWord.containedEnd = false;
  //     } else if (checkIfStart === null && checkIfEnd !== null) {
  //       // Type 3 block   abc∇
  //       //text = word.match(regLeftText)[0];
  //       text = word.replace(regEndTag, "");
  //       group = prevWord.group;
  //       selected = true;
  //       prevWord.containedEnd = true;
  //     } else {
  //       if (prevWord.selected && !prevWord.containedEnd) {
  //         // Type 4 block: part of cloze:
  //         selected = true;
  //         group = prevWord.group;
  //       } else {
  //         // Type 4 block: normal
  //         selected = false;
  //         group = 0;
  //       }
  //       prevWord.containedEnd = false;
  //       text = word;
  //     }

  //     prevWord.group = group;
  //     prevWord.selected = selected;
  //     return { text: text, selected: selected, group: group, hint: hint };
  //   });

  //   function getGroupFromStartTag(startTag, startTagDelimiter, charPreceedingGroupNum) {
  //     return parseInt(
  //       startTag[0]
  //         .replace(RegExp(`${startTagDelimiter}`, "g"), "")
  //         .replace(RegExp(":", "g"), "")
  //         .replace(`${charPreceedingGroupNum}`, "") - 1
  //     ); // minus 1 because you +1 when you .parseObject
  //   }
  // }

  static parseText(text) {
    const regStartTag = new RegExp(`([${this.startTagDelimiter}]{${this.numStartDelimiters}})\\b([${this.charPreceedingGroupNum}][0-9]{1,2})\\b([:]{${this.numColonsAfterFirstDelimiter}})`, "gi"); // ∆1:
    const regEndTag = new RegExp(`([${this.endTagDelimiter}]{${this.numEndDelimiters}})`, "gi"); // ∇
    const regSplitBySpacesAndNewLines = /(\r\n|\n|\r|\s)/g;
    const regSplitByHintTag = /([:]{2})/g;

    var temp = text.replace(regSplitByHintTag, "$1 ");

    var split = temp.split(regSplitBySpacesAndNewLines).filter(function (el) {
      return el !== "" && el !== " ";
    });
    let prevWord = { group: 0, selected: false, containedEnd: false, hint: false, hintTagPresent: false };

    let wordMap = split.reduce((words, word) => {
      let text = "";
      let selected = false;
      let group = 0;
      let hint = false;

      let checkIfStart = word.match(regStartTag);
      let checkIfEnd = word.match(regEndTag);

      // Type 1 block:   ∆1:abc∇
      if (checkIfStart !== null && checkIfEnd !== null) {
        text = word.replace(regStartTag, "").replace(regEndTag, "");
        group = getGroupFromStartTag(checkIfStart, this.startTagDelimiter, this.charPreceedingGroupNum);
        selected = true;
        prevWord.containedEnd = true;
      } else if (checkIfStart !== null && checkIfEnd === null) {
        // Type 2 block:   ∆1:abc
        text = word.replace(regStartTag, "");
        group = getGroupFromStartTag(checkIfStart, this.startTagDelimiter, this.charPreceedingGroupNum);
        selected = true;
        prevWord.containedEnd = false;
      } else if (checkIfStart === null && checkIfEnd !== null && prevWord.selected) {
        // Type 3 block   abc∇
        // Could be a hint
        text = word.replace(regEndTag, "");
        group = prevWord.group;
        selected = true;
        hint = prevWord.hintTagPresent || prevWord.hint;
        prevWord.containedEnd = true;
      } else {
        if (prevWord.selected && !prevWord.containedEnd) {
          // Type 4 block: part of cloze:
          // Could be a hint
          selected = true;
          hint = prevWord.hintTagPresent || prevWord.hint;
          group = prevWord.group;
        } else {
          // Type 4 block: normal
          selected = false;
          group = 0;
        }
        prevWord.containedEnd = false;
        text = word;
      }

      prevWord.hint = hint;
      prevWord.group = group;
      prevWord.selected = selected;

      prevWord.hintTagPresent = checkForHint(text);
      text = prevWord.hintTagPresent ? text.replace(regSplitByHintTag, "") : text;

      if (text !== "") {
        words.push({ text: text, selected: selected, group: group, hint: hint });
      }
      return words;
    }, []);

    return wordMap;

    function getGroupFromStartTag(startTag, startTagDelimiter, charPreceedingGroupNum) {
      return parseInt(
        startTag[0]
          .replace(RegExp(`${startTagDelimiter}`, "g"), "")
          .replace(RegExp(":", "g"), "")
          .replace(`${charPreceedingGroupNum}`, "") - 1
      ); // minus 1 because you +1 when you .parseObject
    }

    function checkForHint(text) {
      return text.match(regSplitByHintTag) !== null;
    }
  }

  static parseObject(wordCollection) {
    return wordCollection
      .map(({ text, selected, group, hint }, index) => {
        let selectedClass = this.determineFormattingType(index, wordCollection, selected, group, hint);

        let finalText = "";
        switch (selectedClass) {
          case 0:
            finalText = `${text}`;
            break;
          case 1:
            finalText = `${this.startTagDelimiter.repeat(this.numStartDelimiters)}${this.charPreceedingGroupNum}${group + 1}${":".repeat(
              this.numColonsAfterFirstDelimiter
            )}${text}${this.endTagDelimiter.repeat(this.numEndDelimiters)}`;
            break;
          case 2:
            finalText = `${this.startTagDelimiter.repeat(this.numStartDelimiters)}${this.charPreceedingGroupNum}${group + 1}${":".repeat(this.numColonsAfterFirstDelimiter)}${text}`;
            break;
          case 3:
            finalText = `${text}`;
            break;
          case 4:
            finalText = `${text}${this.endTagDelimiter.repeat(this.numEndDelimiters)}`;
            break;
          case 5:
            finalText = `:: ${text}${this.endTagDelimiter.repeat(this.numEndDelimiters)}`;
            break;
          case 6:
            finalText = `:: ${text}`;
            break;
          case 7:
            finalText = `${text}`;
            break;
          case 8:
            finalText = `${text}${this.endTagDelimiter.repeat(this.numEndDelimiters)}`;
            break;
          default:
            finalText = `${text}`;
            break;
        }

        return finalText;
      })
      .join(" ");
  }

  static determineFormattingType = (index, wordCollection, selected, group, hint = false) => {
    const nextWordHint = wordCollection[index + 1] && wordCollection[index + 1].hint;
    const prevWordHint = wordCollection[index - 1] && wordCollection[index - 1].hint;

    const nextWordSelected = wordCollection[index + 1] && wordCollection[index + 1].selected;
    const prevWordSelected = wordCollection[index - 1] && wordCollection[index - 1].selected;

    const nextWordGroup = wordCollection[index + 1] && wordCollection[index + 1].group;
    const prevWordGroup = wordCollection[index - 1] && wordCollection[index - 1].group;

    const matchNextHint = nextWordHint && group === nextWordGroup;
    const matchPrevHint = prevWordHint && group === prevWordGroup;

    const matchNext = nextWordSelected && group === nextWordGroup;
    const matchPrev = prevWordSelected && group === prevWordGroup;

    let selectedClass = 0;
    // Determine styling for a fragment based on surrounding fragments
    if (selected && !hint) {
      // selected
      if (matchPrev) {
        if (matchNext) {
          selectedClass = 3; // prev + next
        } else {
          selectedClass = 4; // prev + !next
        }
      } else {
        if (matchNext) {
          selectedClass = 2; // !prev + next
        } else {
          selectedClass = 1; // !prev + !next
        }
      }
    } else if (hint) {
      if (matchPrevHint) {
        if (matchNextHint) {
          selectedClass = 7; // prev + next
        } else {
          selectedClass = 8; // prev + !next
        }
      } else {
        if (matchNextHint) {
          selectedClass = 6; // !prev + next
        } else {
          selectedClass = 5; // !prev + !next
        }
      }
    }

    return selectedClass;
  };

  static determineHintPosition = (index, wordCollection, hint) => {
    const prevWordHint = wordCollection[index - 1] && wordCollection[index - 1].hint;
    const nextWordHint = wordCollection[index + 1] && wordCollection[index + 1].hint;
    return hint ? (prevWordHint && !nextWordHint ? 3 : prevWordHint && nextWordHint ? 2 : !prevWordHint && nextWordHint ? 1 : 4) : 0;
  };

  static determineSelectClass = (index, wordCollection, selected, group) => {
    // const nextWordHint = wordCollection[index + 1] && wordCollection[index + 1].hint;

    const nextWordSelected = wordCollection[index + 1] && wordCollection[index + 1].selected;
    const prevWordSelected = wordCollection[index - 1] && wordCollection[index - 1].selected;

    const nextWordGroup = wordCollection[index + 1] && wordCollection[index + 1].group;
    const prevWordGroup = wordCollection[index - 1] && wordCollection[index - 1].group;

    // const matchNextHint = nextWordHint && group === nextWordGroup;
    // const matchPrevHint = prevWordHint && group === prevWordGroup;

    const matchNext = nextWordSelected && group === nextWordGroup;
    const matchPrev = prevWordSelected && group === prevWordGroup;

    let selectedClass = 0;

    // Determine styling for a fragment based on surrounding fragments
    if (selected) {
      // selected
      if (matchPrev) {
        if (matchNext) {
          selectedClass = 3; // prev + next
        } else {
          selectedClass = 4; // prev + !next
        }
      } else {
        if (matchNext) {
          selectedClass = 2; // !prev + next
        } else {
          selectedClass = 1; // !prev + !next
        }
      }
    }

    return selectedClass;
  };
}

export default Flashify;
