import React from "react";

const ClientMessage = ({ type, text }) => {
  return (
    <div className="msgContainer">
      <span className={`statusMsg ${type ? `msg${type}` : ""}`}>{text}</span>
    </div>
  );
};

export default ClientMessage;
