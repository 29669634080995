import React, { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";

import "./TextArea.scss";

const TextArea = ({ value, setTextBeingEdited }) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setTextBeingEdited(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <TextareaAutosize
      autoFocus
      maxRows="100"
      onChange={(e) => {
        setText(e.target.value);
      }}
      value={text}
    />
  );
};

export default TextArea;
