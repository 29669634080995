import React, { useState, createContext } from "react";
import { v4 as uuid } from "uuid";

export const InputContext = createContext();

export const InputProvider = (props) => {
  let currentDate = new Date();
  const [masterText, setMasterText] = useState(() => {
    let obj = localStorage.getItem("mastertext");
    try {
      obj = JSON.parse(obj);
      if (typeof obj !== "object" || obj === null) throw Error;
      if (!obj.hasOwnProperty("flashnotez") || !obj.hasOwnProperty("title")) throw Error;
    } catch (error) {
      obj = { id: uuid(), title: "", flashnotez: [], localDate: currentDate, remoteDate: currentDate, isRemoteSaved: false };
    }
    return obj;
  });

  const clearMasterText = () => {
    localStorage.removeItem("mastertext");
    setMasterText({ id: uuid(), title: "", flashnotez: [], localDate: currentDate, remoteDate: currentDate, isRemoteSaved: false });
  };

  const handleMasterTextUpdate = (newMasterText, reset = false) => {
    if (newMasterText !== null) {
      localStorage.setItem("mastertext", JSON.stringify(newMasterText));
      setMasterText(() => {
        return newMasterText;
      });
    }
    if (reset) {
      clearMasterText();
    }
  };

  return <InputContext.Provider value={[masterText, handleMasterTextUpdate]}>{props.children}</InputContext.Provider>;
};
