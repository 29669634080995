import React from "react";

import "./FeaturePreviewSection.scss";

const FeaturePreviewSection = ({
  color = "default",
  type = "default",
  rotate = "none",
  children,
}) => {
  return (
    <section
      className={`feature-section section-${color} section-${type} section-rotate-${rotate}`}
    >
      {children}
    </section>
  );
};

export default FeaturePreviewSection;
