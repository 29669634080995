import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import LoginForm from "../components/LoginForm/LoginForm";

const Login = ({ history }) => {
  const [open, setOpen] = useState(true);

  const successfulLogin = () => {
    window.location.reload();
  };

  return (
    <div>
      <LoginForm open={open} onCloseModal={() => setOpen(false)} onSuccess={successfulLogin} canCloseModal={false} />
    </div>
  );
};

export default withRouter(Login);
