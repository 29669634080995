import React, { useEffect, useState, useContext } from "react";

import FlashNote from "../FlashNote/FlashNote";
import DefaultButton from "../DefaultButton/DefaultButton";

import { v4 as uuid } from "uuid";
import DOMPurify from "dompurify";

import { InputContext } from "../../contexts/InputTextContext";

import { HelpTextData } from "../../data/HelpTextData";

import "./EditArea.scss";
import Flashify from "../FlashNote/FlashNoteLogic";

const EditArea = ({ setHelpText }) => {
  const [masterText, setMasterText] = useContext(InputContext);

  const [flashNoteStatus, setFlashNoteStatus] = useState(() => {
    if (masterText.flashnotez.length > 0) {
      return masterText.flashnotez.map(() => false);
    } else {
      return [false];
    }
  });

  const handlePasteEvent = (e) => {
    if (flashNoteStatus.every((f) => f === false)) {
      // if no flashcards are being edited then allow paste creation
      handleCreateNewFlashNote(DOMPurify.sanitize(e.clipboardData.getData("text").trim()));
    }
  };

  useEffect(() => {
    window.addEventListener("paste", handlePasteEvent);
    updateHelpText();

    return () => {
      window.removeEventListener("paste", handlePasteEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterText]);

  const handleCreateNewFlashNote = (text = "") => {
    // Parse the clean text into a wordCollection object
    const wordCollection = Flashify.parseText(text.trim());
    setMasterText({
      ...masterText,
      flashnotez: [
        ...masterText.flashnotez,
        {
          id: uuid(),
          index: masterText.flashnotez.length,
          title: "", //FlashNote-${masterText.flashnotez.length + 1}
          wordCollection: wordCollection,
        },
      ],
    });
  };

  const handleDeleteFlashNote = (noteIndex) => {
    // Set the new wordCollection for the flashnote
    //let newMasterText = masterText;

    let newFlashnotez = [...masterText.flashnotez];
    if (noteIndex > -1) {
      newFlashnotez.splice(noteIndex, 1);
    }
    setMasterText({ ...masterText, flashnotez: newFlashnotez });

    let newFlashNoteStatus = masterText.flashnotez.map(() => false);
    setFlashNoteStatus(newFlashNoteStatus);
  };

  // If there are less than 1 flashnotez show the instructions
  const updateHelpText = () => {
    if (masterText.flashnotez.length < 1) {
      setHelpText(HelpTextData.welcome);
    } else {
      setHelpText(HelpTextData.blank);
    }
  };

  const handleAddFlashNoteClicked = () => {
    handleCreateNewFlashNote();
  };

  const handleInteractionTab = (e, noteIndex) => {
    if (masterText.flashnotez.length === noteIndex + 1) {
      // If tab is pressed while editing the last card, create a new card
      e.preventDefault();
      handleCreateNewFlashNote();
    }

    // Else automatically focus the next text area
  };

  return (
    <div id="text-field">
      {masterText.flashnotez.map((flashcard, index) => (
        <FlashNote
          setHelpText={setHelpText}
          key={flashcard.id}
          noteIndex={index}
          title={flashcard.title}
          wordCollection={flashcard.wordCollection}
          flashNoteStatus={flashNoteStatus}
          setFlashNoteStatus={setFlashNoteStatus}
          handleDeleteFlashNote={handleDeleteFlashNote}
          handleInteractionTab={handleInteractionTab}
        />
      ))}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DefaultButton className="addFlashNoteBtn" text="＋FlashNote" callback={handleAddFlashNoteClicked} />
      </div>
    </div>
  );
};

export default EditArea;
