import React, { useState, useContext } from "react";

import { InputContext } from "../../contexts/InputTextContext";

import "./AppContainer.scss";

//COMPONENTS
import HelpText from "../HelpText/HelpText";
import EditArea from "../EditArea/EditArea";

//DATA
import { HelpTextData } from "../../data/HelpTextData";

const AppContainer = () => {
  const [masterText] = useContext(InputContext);
  const [helpText, setHelpText] = useState(() => {
    return masterText.flashnotez.length < 1 ? HelpTextData.welcome : HelpTextData.blank;
  });

  return (
    <div id="app-container">
      <HelpText text={helpText} />
      <EditArea setHelpText={setHelpText} />
    </div>
  );
};

export default AppContainer;
