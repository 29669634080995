import React from "react";
import { withRouter } from "react-router-dom";
import { CgNotes } from "react-icons/cg";

import "./Collection.scss";

const Collection = ({ id, title, numFlashNotez, history }) => {
  const collectionId = id;

  return (
    <div className="collectionContainer" onClick={() => history.push(`/create/${collectionId}`)}>
      <div className="icon">
        <CgNotes color="#469cff" />
      </div>
      {title !== "" ? title : `A collection of ${numFlashNotez} flashnotez`}
    </div>
  );
};

export default withRouter(Collection);
