import React, { useState, useEffect, useContext, useRef } from "react";
import { isMobile } from "../../data/HelpTextData";
import { InputContext } from "../../contexts/InputTextContext";

import ColourGroups from "../ColourGroups/ColourGroups";
import NoteFragment from "../NoteFragment/NoteFragment";
import DefaultButton from "../DefaultButton/DefaultButton";
import TextArea from "../TextArea/TextArea";

import NoteBreak from "../NoteBreak/NoteBreak";

import "./FlashNote.scss";

//Helper Logic
import Flashify from "./FlashNoteLogic";
import { initialNumColourGroups } from "../../misc/helperfunctions";
import { confirmAlert } from "react-confirm-alert";
import "../../styles/react-confirm-alert.css";

const FlashNote = ({ noteIndex, title, flashNoteStatus, setFlashNoteStatus, handleDeleteFlashNote, handleInteractionTab }) => {
  const [masterText, setMasterText] = useContext(InputContext);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [selecting, setSelecting] = useState(0);
  const [textBeingEdited, setTextBeingEdited] = useState("");
  const [editingText, setEditingText] = useState(masterText.flashnotez[noteIndex].wordCollection.length < 1);
  const [noteTitle, setNoteTitle] = useState(title);
  const [newSelection, setNewSelection] = useState([]);
  const [innerElements, setInnerElements] = useState(<div></div>);

  const fragmentEls = useRef({});

  const mobileClient = isMobile();

  // ***************************************************** //
  // *** Functions for updates to master text / parent *** //
  // ***************************************************** //

  // Updates the newSelection temporary variable
  const updateNewSelection = (index, selected) => {
    let tempSelection = [...newSelection];

    let curIndex = index;
    const propogate = () => {
      tempSelection[curIndex] = selected;
      if (!selected && masterText.flashnotez[noteIndex].wordCollection[curIndex + 1] && masterText.flashnotez[noteIndex].wordCollection[curIndex + 1].hint) {
        curIndex = curIndex + 1;
        propogate();
      }
    };

    propogate();

    setNewSelection(tempSelection);
  };

  useEffect(() => {
    let newWordCollection = masterText.flashnotez[noteIndex].wordCollection;

    // Loop through words and change their group & selection status
    newSelection.forEach((selected, index) => {
      if (selected !== undefined) {
        newWordCollection[index].group = currentGroup;
        newWordCollection[index].selected = selected;
      }
    });
    newWordCollection = newWordCollection.filter((fragment) => !(!fragment.selected && fragment.hint));

    // Set the new wordCollection for the flashnote
    let newFlashnotez = [...masterText.flashnotez];
    newFlashnotez[noteIndex].wordCollection = newWordCollection;

    // Update the master reference object
    setMasterText({ ...masterText, flashnotez: newFlashnotez });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSelection]);

  // Updates/Creates Hints
  const updateHint = (index, text) => {
    let newWordCollection = masterText.flashnotez[noteIndex].wordCollection;
    if (newWordCollection[index]) {
      let group = newWordCollection[index].group;
      let textArr = text.split(" ");

      let curIndex = index + 1;
      textArr.forEach((word) => {
        newWordCollection.splice(curIndex, 0, { text: word, selected: true, group: group, hint: true });
        curIndex += 1;
      });
      let newFlashnotez = [...masterText.flashnotez];
      newFlashnotez[noteIndex].wordCollection = newWordCollection;
      setMasterText({ ...masterText, flashnotez: newFlashnotez });
    }
  };

  // ********************************************* //
  // *** Functions for creating/modifying text *** //
  // ********************************************* //

  // Creates the inner elements for the flashnote
  useEffect(() => {
    let newFlashNoteStatus = flashNoteStatus;
    newFlashNoteStatus[noteIndex] = editingText;
    setFlashNoteStatus(newFlashNoteStatus);

    if (!editingText) {
      setInnerElements(() =>
        masterText.flashnotez[noteIndex].wordCollection.map(({ text, selected, group, hint }, index) => {
          let selectedClass = Flashify.determineSelectClass(index, masterText.flashnotez[noteIndex].wordCollection, selected, group, hint);
          let hintPosition = Flashify.determineHintPosition(index, masterText.flashnotez[noteIndex].wordCollection, hint);
          let curGroup = group === 0 && !selected ? currentGroup : group;
          return text.trim() !== "" ? ( // if the text is not whitespace return NoteFragment
            <NoteFragment
              selected={selected}
              hint={hint}
              hintPosition={hintPosition}
              updateHint={updateHint}
              selectedClass={selectedClass}
              selecting={selecting}
              setSelecting={setSelecting}
              selectingMode={selecting}
              updateNewSelection={updateNewSelection}
              group={curGroup}
              key={index}
              index={index}
              innerRef={(element) => (fragmentEls.current[index] = element)}
              text={text}
              handleDoubleClick={handleDoubleClick}
            />
          ) : (
            // else return a new line
            <NoteBreak key={index} />
          );
        })
      );
    } else {
      const parsedText = Flashify.parseObject(masterText.flashnotez[noteIndex].wordCollection);

      setInnerElements(() => <TextArea setTextBeingEdited={setTextBeingEdited} value={parsedText}></TextArea>);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingText, masterText, selecting, currentGroup]);

  // Parses new text and creates a new master text
  const updateText = (newText) => {
    const newWordCollection = Flashify.parseText(newText);

    // Set the new wordCollection for the flashnote
    let newFlashnotez = [...masterText.flashnotez];
    newFlashnotez[noteIndex].wordCollection = newWordCollection;

    // Update the master reference object
    setEditingText(false);
    setSelecting(0);
    setMasterText({ ...masterText, flashnotez: newFlashnotez });
  };

  // **************************************** //
  // *** Handlers for changing edit modes *** //
  // **************************************** //

  // Changes from cloze mode to edit mode
  const handleDoubleClick = (e) => {
    e.preventDefault();
    setEditingText(true);
  };

  // Toggles between edit mode and cloze mode
  const editTextClicked = () => {
    if (editingText && textBeingEdited.length > 0) {
      updateText(textBeingEdited);
    }
    // Determine whether to toggle
    if (textBeingEdited.length > 0 || !editingText) {
      setEditingText(!editingText);
    }
  };

  // Change the cloze group selection
  const changeGroupSelection = (newGroupId) => {
    if (editingText) {
      if (textBeingEdited.length > 0) {
        updateText(textBeingEdited);
        setEditingText(false);
        setCurrentGroup(newGroupId);
      }
    } else {
      setCurrentGroup(newGroupId);
    }
  };

  const confirmDelete = () => {
    if (textBeingEdited.length > 0 || !editingText) {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure you want to delete this FlashNote?",
        buttons: [
          { label: "Delete", onClick: () => handleDeleteFlashNote(noteIndex) },
          { label: "Cancel", onClick: () => console.log("cancelled") },
        ],
      });
    } else {
      handleDeleteFlashNote(noteIndex);
    }
  };

  // ************************************************* //
  // *** Handlers for editing FlashNote properties *** //
  // ************************************************* //

  const handleTitleChange = (newValue = "") => {
    setNoteTitle(newValue);
  };

  useEffect(() => {
    let newFlashnotez = [...masterText.flashnotez];
    newFlashnotez[noteIndex].title = noteTitle;
    setMasterText({ ...masterText, flashnotez: newFlashnotez });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteTitle]);

  // ************************************************** //
  // *** Handlers for selecting cloze deletion text *** //
  // ************************************************** //

  // mouseUp + touchEnd
  const handleInteractionEnd = (e) => {
    if (!editingText) {
      setSelecting(0);
      setNewSelection([]);
    }
  };

  // mouseDown + touchStart
  const handleInteractionStart = (e) => {
    if (selecting === 0) {
      let index = getIndexOfElementFromE(e);
      let isSelected = masterText.flashnotez[noteIndex]?.wordCollection[index]?.selected;
      let curGroup = masterText.flashnotez[noteIndex]?.wordCollection[index]?.group;
      if (isSelected === false || currentGroup !== curGroup) {
        selectFragment(index);
        updateSelectionMode(1);
      } else if (isSelected === true) {
        deselectFragment(index);
        updateSelectionMode(2);
      }
    }
  };

  // mouseOver + touchMove
  const handleInteractionOver = (e) => {
    if (selecting === 1 || selecting === 2) {
      let isSelected;
      let index = getIndexOfElementFromE(e);
      if (index !== false) {
        isSelected = masterText.flashnotez[noteIndex]?.wordCollection[index]?.selected;
        let curGroup = masterText.flashnotez[noteIndex]?.wordCollection[index]?.group;
        if (isSelected === false || currentGroup !== curGroup) {
          if (selecting === 1) {
            selectFragment(index);
          }
        } else if (isSelected === true) {
          if (selecting === 2) {
            deselectFragment(index);
          }
        }
      }
    }
  };

  const handleInitialInteractionTab = (e) => {
    editTextClicked();
    handleInteractionTab(e, noteIndex);
  };

  function getIndexOfElementFromE(e) {
    if (e) {
      let currentElementTouched;
      if (e.touches) {
        currentElementTouched = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY);
      } else if (e.clientX) {
        currentElementTouched = document.elementFromPoint(e.clientX, e.clientY);
      } else {
        return false;
      }
      let curFrag = currentElementTouched?.attributes?.fragment?.value;
      curFrag = parseInt(curFrag) ?? undefined;
      return curFrag;
    }

    return false;
  }

  const selectFragment = (i) => {
    updateNewSelection(i, true);
  };

  const deselectFragment = (i) => {
    updateNewSelection(i, false);
  };

  const updateSelectionMode = (newSelectionMode) => {
    setSelecting(newSelectionMode);
  };

  return (
    <div>
      <div className="titleBar">
        <input className="titleText noteTitle" type="text" onChange={(e) => handleTitleChange(e.target.value)} value={noteTitle} placeholder={`FlashNote-${noteIndex + 1}`} tabIndex="-1" />
        <ColourGroups initialNumGroups={initialNumColourGroups(masterText.flashnotez[noteIndex])} changeGroupSelection={changeGroupSelection} editingText={editingText} />
        {/* <DefaultButton
          enabled={textBeingEdited.length > 0 || !editingText}
          text={editingText ? "Save" : "Edit"}
          className={editingText && textBeingEdited.length > 0 ? "saveEnabled" : ""}
          callback={editTextClicked}
        /> */}
        <DefaultButton enabled={true} text="Delete" className="deleteButton" callback={confirmDelete} />
      </div>
      <div
        onKeyDown={(e) => {
          if (editingText && e.code === "Tab") handleInitialInteractionTab(e);
        }}
        onMouseDown={(e) => {
          if (!mobileClient && !editingText) handleInteractionStart(e);
        }}
        onMouseUp={(e) => {
          if (!mobileClient && !editingText) handleInteractionEnd(e);
        }}
        onTouchStart={(e) => {
          if (mobileClient && !editingText) handleInteractionStart(e);
        }}
        onTouchEnd={(e) => {
          if (mobileClient && !editingText) handleInteractionEnd(e);
        }}
        onMouseOver={(e) => {
          if (!editingText) handleInteractionOver(e);
        }}
        onTouchMove={(e) => {
          if (mobileClient && !editingText) handleInteractionOver(e);
        }}
        onDoubleClick={(e) => {
          if (!editingText) handleDoubleClick(e);
        }}
        className={`flashNote ${editingText ? "editingNote" : ""}`}
        tabIndex={noteIndex}
      >
        {innerElements}
      </div>
    </div>
  );
};

export default FlashNote;
