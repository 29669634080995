import React from "react";
import DefaultButton from "../DefaultButton/DefaultButton";
import { withRouter } from "react-router-dom";

import "./PageTitle.scss";

const PageTitle = ({ pageTitle, back, history }) => {
  const handleBackButtonClicked = () => {
    if (back) {
      history.push(back);
    }
  };

  return (
    <div className="pageTitleContainer">
      <DefaultButton visible={back ? true : false} text="←" label="Back" callback={() => handleBackButtonClicked()} />
      <h1 className="pageTitle">{pageTitle}</h1>
    </div>
  );
};

export default withRouter(PageTitle);
