import React, { useContext } from "react";
import AppContainer from "../components/AppContainer/AppContainer";
import Wrapper from "../components/Wrapper/Wrapper";
import logo from "../assets/Logo.svg";
import FeaturePreviewSection from "../components/FeaturePreviewSection/FeaturePreviewSection";
import DefaultButton from "../components/DefaultButton/DefaultButton";
import { InputContext } from "../contexts/InputTextContext";
import AnimateContent from "../components/AnimateContent/AnimateContent";
import SocialDetailsGrid from "../components/SocialDetailsGrid/SocialDetailsGrid";

const FeaturePreview = () => {
  const [, setMasterText] = useContext(InputContext);
  return (
    <div className="feature-preview-all">
      <FeaturePreviewSection type="top">
        <Wrapper>
          <div className="text-center">
            <img height="50" src={logo} alt="FlashNotez logo" />
            <h2>Easy and Fun Flashcards</h2>
          </div>
          <SocialDetailsGrid />
        </Wrapper>
      </FeaturePreviewSection>
      <AnimateContent animateStyle="scaleY" delay={500}>
        <FeaturePreviewSection type="flashnote" color="yellow" rotate="left">
          <Wrapper>
            <AnimateContent delay={1000}>
              <h1 className="caption">Try it out!</h1>
            </AnimateContent>
            <AppContainer />
            <div className="resetButtonContainer">
              <DefaultButton
                text="Clear All"
                callback={() => setMasterText(null, true)}
              />
            </div>
          </Wrapper>
        </FeaturePreviewSection>
      </AnimateContent>
    </div>
  );
};

export default FeaturePreview;
