export let HelpTextData = {
  welcome: `${getWelcomeMessage()}
  <h1>${getPasteCommandText()}</h1>`,
  instructions: getInstructions(),
  editText: "✍️ Click edit to change the text of this note",
  blank: "",
  notLoggedIn: "⚡ Login to start creating flashnotez! ⚡",
};

function getInstructions() {
  if (isMobile()) {
    return "👇 Tap and slide to create a new cloze deletion";
  }
  return "👇 Click and drag to create a new cloze deletion";
}

function getWelcomeMessage() {
  if (isMobile()) {
    return "👇 Tap the button for a new FlashNote";
  }
  return "Paste some text to start making a FlashNote";
}

function getPasteCommandText() {
  let clientOS = getOS();

  switch (clientOS) {
    case "Mac OS":
      return "⌘ + v";
    case "Windows":
      return "Ctrl + v";
    case "Linux":
      return "";
    case "iOS":
      return "";
    case "Android":
      return "";
    default:
      return "";
  }
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  } else {
    return "";
  }

  return os;
}

export function isMobile() {
  const clientOS = getOS();
  return clientOS === "Android" || clientOS === "iOS";
}
