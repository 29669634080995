export function initialNumColourGroups(flashnote) {
  // get the highest group
  if (flashnote) {
    if (flashnote.wordCollection.length > 0) {
      let res = flashnote.wordCollection.reduce(function (prev, curr) {
        return prev.group < curr.group ? curr : prev;
      }).group;
      if (res < 1) {
        return 0;
      } else if (res < 16) {
        return res;
      } else {
        return 15;
      }
    } else {
      return 0;
    }
  }
}
